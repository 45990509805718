import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { UserInterface } from '../models/user-interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token = '';
  url_api = 'https://hr.miplanning.es/index.php';
  // url_api = 'http://localhost/estrella/index.php';
  modulo = 'usuario_ws';
  op = 'login';
  q = 'mip/ajax';

  constructor(private http: HttpClient, public router: Router) {}
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  loginuser(datousuario: string, datopassword: string) {
    this.token = this.getToken();

    const params = new HttpParams()
      .set('modulo', this.modulo)
      .set('op', this.op)
      .set('datousuario', datousuario)
      .set('datopassword', datopassword)
      .set('q', this.q);

     return this.http.get<UserInterface>(
              this.url_api, { headers: this.headers, params })
              .subscribe( data => {
                this.setSession(data['datosTrabajador']);
                console.log(data);
              });
  }

  logoutUser() {
    const token = this.getToken();
    const headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    const params = new HttpParams()
        .set('modulo', this.modulo)
        .set('op', 'logout')
        .set('q', this.q)
        .set('token', token);

    this.http.get(this.url_api, { headers: headers, params})
                  .subscribe( data => {
                    console.log(data);
                  });
    localStorage.removeItem('access_token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('nomUser');
    localStorage.removeItem('codUser');
    // this.router.navigate(['/login']);
}

  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn + 3600;
    localStorage.setItem('access_token', authResult.token);
    localStorage.setItem('currentUser', authResult.usuario);
    localStorage.setItem('nomUser', authResult.nombre + ' ' + authResult.apellidos);
    localStorage.setItem('codUser', authResult.codigo);
    localStorage.setItem('expires_at', expiresAt);
    this.router.navigate(['/mip/home']);
  }

  setToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  getCurrentUser(): UserInterface {
    const user_string = localStorage.getItem('currentUser');
    if (!isNullOrUndefined(user_string)) {
      const user: UserInterface = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  isAuthenticated(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() / 1000 < expiresAt;
  }


}
