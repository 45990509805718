import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule } from '@ionic/angular';

import { LoginPage } from './login.page';
import { AuthService } from 'src/app/services/auth.service';

// const routes: Routes = [
//   { path: '',
//     component: LoginPage
//   },
//   { path: 'mip', loadChildren: './tabs/tabs.module#TabsPageModule' }
// ];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // RouterModule.forChild(routes),
    HttpClientModule
  ],
  declarations: [LoginPage],
  providers: [AuthService],
  // exports: [RouterModule]
})
export class LoginPageModule {}
