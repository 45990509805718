import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { UserInterface } from '../../models/user-interface';
import { NgForm } from '@angular/forms/src/directives/ng_form';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  constructor(private authService: AuthService) { }
  private user: any = {
    datousuario: '',
    datopassword: ''
  };

  public isError = false;

  ngOnInit() { }

  onLogin(form: NgForm) {
    if (form.valid) {
      this.authService
        .loginuser(this.user.datousuario, this.user.datopassword);
    } else {
      this.onIsError();
    }
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 4000);
  }

}
