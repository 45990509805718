import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// services
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Notificaciones',
      url: '/mip/notificaciones',
      icon: 'mail'
    },
    {
      title: 'Solicitudes',
      url: '/mip/solicitudes',
      icon: 'filing'
    },
    {
      title: 'Intercambio de servicio',
      url: '/mip/intercambios',
      icon: 'today'
    },
    {
      title: 'Cambiar contraseña',
      url: '/mip/passwordreset',
      icon: 'information-circle'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
  ) {
    this.initializeApp();
    // const isAuth = loginService.isAuthenticated();
    // console.log(isAuth);
  }

  public isError = false;

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  onLogout(): void {
    this.authService.logoutUser();
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 4000);
  }
}
