import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { Page404Page } from './pages/page404/page404.page';

import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPage },
  { path: 'page404', component: Page404Page },
  { path: 'mip', loadChildren: './tabs/tabs.module#TabsPageModule', canActivate: [ AuthGuardService ]},
  { path: '**', pathMatch: 'full', redirectTo: 'page404' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
